type Replacement = [string, string, string];

export default function richText() {
  const textareas = Array.from(document.querySelectorAll("textarea.rich"));

  const getSelection = (elem: HTMLTextAreaElement) => {
    return elem.value.substr(
      elem.selectionStart,
      elem.selectionEnd - elem.selectionStart
    );
  };

  const getSelectionRange = (elem: HTMLTextAreaElement) => {
    if (typeof elem.selectionStart !== "undefined") {
      return [elem.selectionStart, elem.selectionEnd];
    } else {
      return [0, 0];
    }
  };

  const setSelectionRange = (
    elem: HTMLTextAreaElement,
    start: number,
    end: number
  ) => {
    if (typeof elem.setSelectionRange !== "undefined") {
      return elem.setSelectionRange(start, end);
    } else {
      return null;
    }
  };

  const adjustSelection = (
    elem: HTMLTextAreaElement,
    callback: () => [number, number]
  ) => {
    const selectionLength = getSelection(elem).length;
    const [start, end] = getSelectionRange(elem);
    const [replacementLength, prefixLength] = callback();
    const newEnd = end + (replacementLength - selectionLength) + prefixLength;
    const newStart = start === end ? newEnd : start + prefixLength;
    return setSelectionRange(elem, newStart, newEnd);
  };

  const replaceSelection = (
    elem: HTMLTextAreaElement,
    prefix: string,
    replacement: string,
    postfix: string
  ) => {
    return adjustSelection(elem, () => {
      elem.value =
        elem.value.substr(0, elem.selectionStart) +
        prefix +
        replacement +
        postfix +
        elem.value.substr(elem.selectionEnd, elem.value.length);
      elem.focus();
      return [replacement.length, prefix.length];
    });
  };

  textareas.forEach(function (textarea: HTMLTextAreaElement) {
    const toolbar = document.createElement("ul");
    toolbar.classList.add("rich-text-toolbar");
    textarea.parentNode.insertBefore(toolbar, textarea);

    const addButton = (
      name: string,
      className: string,
      callback: (string) => Replacement
    ) => {
      const listItem = document.createElement("li");
      toolbar.appendChild(listItem);

      const button = document.createElement("button");
      button.title = name;
      button.classList.add(className);
      listItem.appendChild(button);

      const icon = document.createElement("i");
      icon.classList.add("fa");
      icon.classList.add(`fa-${className}`);
      button.appendChild(icon);

      button.addEventListener("click", function (e: Event) {
        e.preventDefault();
        const result = callback(getSelection(textarea));
        if (result) {
          const [prefix, replacement, postfix] = result;
          replaceSelection(textarea, prefix, replacement, postfix);
        }
      });
    };

    addButton("Bold", "bold", (str: string) => ["<b>", str, "</b>"]);
    addButton("Italics", "italic", (str: string) => ["<i>", str, "</i>"]);

    addButton("Link", "link", (s: string) => {
      const name = s.length > 0 ? s : "Link text";
      let url = prompt("Enter link URL", "");
      url = url.length > 0 ? url : "http://example.com/";
      url = url.replace(/^(?!(f|ht)tps?:\/\/)/, "http://");
      return ['<a href="' + url + '">', name, "</a>"];
    });
  });
}
