import Sortable from "sortablejs";

import csrfToken from "../lib/csrfToken";

export default function sortableList() {
  const tables = Array.from(document.querySelectorAll("tbody.sortable"));

  tables.forEach(function (table: HTMLTableElement) {
    function listItems() {
      return Array.from(table.querySelectorAll("tr"));
    }

    // Add handles
    listItems().forEach(function (tr: HTMLElement) {
      const handle = document.createElement("td");
      handle.classList.add("drag-handle");
      tr.prepend(handle);
    });

    // Apply sortable
    Sortable.create(table, {
      handle: ".drag-handle",
      draggable: "tr",
      onUpdate: function () {
        const ids = listItems().map((tr) => tr.dataset.id);

        const req = new XMLHttpRequest();
        req.open("POST", table.dataset.path);
        req.setRequestHeader("Content-Type", "application/json");
        req.setRequestHeader("X-CSRF-Token", csrfToken());
        req.send(JSON.stringify({ ids: ids }));
      }
    });
  });
}
