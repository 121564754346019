import React from "react";
import ListItem from "./ListItem";
import { paginate } from "./useSalesStore";

interface Props {
  state: SalesState;
  sales: Sale[];
}

export default function List(props: Props) {
  const sales = paginate(props.state, props.sales);

  if (sales.length > 0) {
    return (
      <div id="recent-sales-list" className="list" aria-live="polite">
        <div className="list-header">
          <div className="cover" />
          <div className="details">
            <div className="column name">
              Author and/or Illustrator
              <br />
              English title
              <br />
              (Original title)
            </div>
            <div className="column sold-to">
              Sold to language / country
              <br />
              Sold to publisher
              <br />
              Translator
            </div>
            <div className="column genre">Genre</div>
            <div className="column dates">
              Rights sold
              <br />
              Due
            </div>
          </div>
        </div>
        {sales.map((s) => (
          <ListItem key={s.id} sale={s} />
        ))}
      </div>
    );
  } else {
    return (
      <div className="no-matches">
        <p>No sales found for the current selection</p>
      </div>
    );
  }
}
