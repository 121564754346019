export default function welcomeNotice() {
  if (!window.localStorage) {
    return;
  }

  let hiddenNotices = [];

  if (window.localStorage.hiddenNotices) {
    hiddenNotices = JSON.parse(
      window.localStorage.hiddenNotices as string
    ) as number[];
  }

  const notices = document.getElementsByClassName("welcome-notice");

  function closeNotice(elem: HTMLElement) {
    hiddenNotices.push(elem.id);
    window.localStorage.hiddenNotices = JSON.stringify(hiddenNotices);
    elem.classList.add("closing");
    setTimeout(function () {
      elem.classList.add("hidden");
    }, 200);
  }

  [...notices].forEach(function (notice: HTMLElement) {
    if (hiddenNotices.indexOf(notice.id) >= 0) {
      notice.classList.add("hidden");
      return;
    }

    const closeButton = document.createElement("a");
    closeButton.classList.add("close");
    closeButton.text = "Close";
    notice.insertBefore(closeButton, notice.firstChild);
    closeButton.addEventListener("click", function () {
      closeNotice(notice);
    });
  });
}
