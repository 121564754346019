import React from "react";
import ReactPaginate from "react-paginate";
import { pageCount } from "./useSalesStore";

interface Props {
  dispatch: (action: SalesAction) => void;
  state: SalesState;
  sales: Sale[];
  scrollToTop: () => void;
}

interface PageAttrs {
  selected: number;
}

export default function Pagination(props: Props) {
  const { page } = props.state;
  const pages = pageCount(props.state, props.sales);

  const changePage = (attrs: PageAttrs) => {
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
    props.dispatch({ type: "changePage", payload: attrs.selected + 1 });
    if (props.scrollToTop) {
      props.scrollToTop();
    }
  };

  if (pages == 1) {
    return <></>;
  } else {
    return (
      <ReactPaginate
        forcePage={page - 1}
        disableInitialCallback={true}
        onPageChange={changePage}
        pageCount={pages}
        pageRangeDisplayed={7}
        marginPagesDisplayed={1}
        nextLabel="Next"
        previousLabel="Previous"
        activeClassName="current"
        containerClassName="pagination"
      />
    );
  }
}
