import Vibrant from "node-vibrant";

export default function vibrantColors() {
  const cover: HTMLImageElement = document.querySelector(
    "section.book .cover.image img"
  );

  function applyColor(color: string, selector: string) {
    const elems = Array.from(document.querySelectorAll(selector));
    elems.forEach(function (e: HTMLElement) {
      e.style.color = color;
    });
  }

  const loadImage = () => {
    void Vibrant.from(cover)
      .getPalette()
      .then((palette) => {
        const color = palette.DarkVibrant.getHex();
        applyColor(color, ".drop-cap");
        applyColor(color, ".endorsements blockquote");
      });
  };

  if (cover) {
    if (cover.complete) {
      loadImage();
    } else {
      cover.addEventListener("load", loadImage);
    }
  }
}
