export default function dropCap() {
  const texts = [...document.getElementsByClassName("with-drop-cap")];

  function applyDropCap(elem: HTMLElement) {
    if (!elem) {
      return;
    }
    elem.innerHTML = elem.innerHTML.replace(
      /^[ABCDEFGHIKLMNOPQRSTUVWXYZÆØÅ]/,
      function (l) {
        return `<span class="drop-cap">${l}</span>`;
      }
    );
  }

  texts.forEach(function (t: HTMLElement) {
    applyDropCap(t.querySelector("p:first-child"));
  });
}
