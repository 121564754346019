import React from "react";

import Dropdown, { DropdownOption } from "./Dropdown";

interface Props {
  dispatch: (action: SalesAction) => void;
  state: SalesState;
  sales: Sale[];
}

export default function Filter(props: Props) {
  const quarters: DropdownOption[] = [
    ["All quarters", ""],
    ["Q1", "1"],
    ["Q2", "2"],
    ["Q3", "3"],
    ["Q4", "4"]
  ];

  const options = (blankLabel: string, items: string[]): DropdownOption[] => {
    return [[blankLabel, ""], ...items.map((i) => [i, i] as DropdownOption)];
  };

  return (
    <div className="filter">
      <Dropdown
        label="Year"
        name="year"
        options={options("All years", props.state.options.year)}
        value={props.state.year}
        onChange={(val: string) =>
          props.dispatch({ type: "changeYear", payload: val })
        }
      />
      <Dropdown
        label="Quarter"
        disabled={!props.state.year}
        name="quarter"
        options={quarters}
        value={props.state.quarter}
        onChange={(val: string) =>
          props.dispatch({ type: "changeQuarter", payload: val })
        }
      />
      <Dropdown
        label="Language/Country"
        name="soldTo"
        options={options("All languages/countries", props.state.options.soldTo)}
        value={props.state.soldTo}
        onChange={(val: string) =>
          props.dispatch({ type: "changeSoldTo", payload: val })
        }
      />
      <Dropdown
        label="Genre"
        name="genre"
        options={options("All genres", props.state.options.genre)}
        value={props.state.genre}
        onChange={(val: string) =>
          props.dispatch({ type: "changeGenre", payload: val })
        }
      />
    </div>
  );
}
