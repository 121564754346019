export default function mobileNav() {
  const header = document.getElementsByTagName("header")[0];
  const nav = document.getElementsByTagName("nav")[0];
  const toggle = document.getElementsByClassName("nav-toggle")[0];
  toggle.addEventListener("click", function () {
    nav.classList.toggle("shown");

    toggle.setAttribute(
      "aria-expanded",
      nav.classList.contains("shown") ? "true" : "false"
    );

    setTimeout(function () {
      header.classList.toggle("show-nav");
    }, 100);
  });
}
