export default function forms() {
  const textareas = [
    ...document.getElementsByTagName("textarea")
  ] as HTMLTextAreaElement[];

  // Automatically resize textareas
  function resizeTextareas() {
    textareas.forEach(function (t: HTMLTextAreaElement) {
      const setHeight = function () {
        t.style.height = "10px";
        t.style.height = t.scrollHeight + 20 + "px";
      };

      t.addEventListener("change", setHeight);
      t.addEventListener("keydown", setHeight);
      t.addEventListener("keyup", setHeight);
      setHeight();
    });
  }

  // Show max length on inputs
  textareas.forEach(function (t) {
    const field = t.closest(".field");
    if (t.dataset.maxLength) {
      const maxLength: number = parseInt(t.dataset.maxLength);
      const indicator = document.createElement("span");
      indicator.classList.add("length-indicator");
      indicator.setAttribute("aria-live", "polite");
      field.appendChild(indicator);

      const checkLength = function () {
        // Account for the fact that line breaks are sent as "\r\n" over
        // the wire.
        const length = t.value.length + (t.value.split("\n").length - 1);

        indicator.innerHTML = `${length}/${maxLength}`;
        if (length > maxLength) {
          field.classList.add("transient-errors");
        } else {
          field.classList.remove("transient-errors");
        }
      };

      t.addEventListener("change", checkLength);
      t.addEventListener("keydown", checkLength);
      t.addEventListener("keyup", checkLength);
      checkLength();
    }
  });

  setTimeout(resizeTextareas, 100);
}
