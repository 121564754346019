export default function represent() {
  const form = document.getElementsByClassName(
    "represent-form"
  )[0] as HTMLFormElement;
  if (form) {
    const select = form.getElementsByTagName("select")[0];
    select.addEventListener("change", function () {
      form.submit();
    });
  }
}
