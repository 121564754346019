export default function expandableSubnav() {
  const navs = [...document.getElementsByClassName("expandable-nav")];

  navs.forEach(function (nav: HTMLElement) {
    const toggle = nav.getElementsByClassName("toggle")[0];

    toggle.addEventListener("click", function () {
      nav.classList.toggle("shown");
      toggle.setAttribute(
        "aria-expanded",
        nav.classList.contains("shown") ? "true" : "false"
      );
      setTimeout(function () {
        nav.classList.toggle("animate");
      }, 50);
    });
  });
}
