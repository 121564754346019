import csrfToken from "../lib/csrfToken";

interface Response {
  id: number;
  preview_url: string;
}

function clearItems(dt: DataTransfer) {
  if (dt.items) {
    for (let i = 0; i < dt.items.length; i++) {
      dt.items.remove(i);
    }
  } else {
    dt.clearData();
  }
}

function getFiles(dt: DataTransfer): File[] {
  const files: File[] = [];
  if (dt.items) {
    for (let i = 0; i < dt.items.length; i++) {
      if (dt.items[i].kind == "file") {
        files.push(dt.items[i].getAsFile());
      }
    }
  } else {
    for (let i = 0; i < dt.files.length; i++) {
      files.push(dt.files[i]);
    }
  }
  return files;
}

export default function fileDrop() {
  const maxSize = 10 * 1024 * 1024;
  const validTypes = ["image/jpeg", "image/png"];

  const drops = Array.from(document.getElementsByClassName("file-drop"));

  function uploadImage(file: File, callback: (response: Response) => void) {
    const req = new XMLHttpRequest();
    const data = new FormData();
    data.append("image[file]", file);
    req.open("POST", "/dashboard/images");
    req.setRequestHeader("X-CSRF-Token", csrfToken());
    req.addEventListener("load", function () {
      const response = JSON.parse(req.responseText) as Response;
      if (callback) {
        callback(response);
      }
    });
    req.send(data);
  }

  drops.forEach(function (drop: HTMLElement) {
    const preview = drop.getElementsByClassName("image")[0];
    // let authToken = drop.closest("form")["authenticity_token"].value;
    const idField = drop.getElementsByClassName(
      "obj-id"
    )[0] as HTMLInputElement;
    const remove = document.createElement("p");
    const fileInput = drop.querySelector("input[type=file]");

    const info = document.createElement("div");
    info.classList.add("info");
    info.innerHTML =
      "<p>Drag and drop image here, or <button " +
      'type="button" class="large">Choose file</button></p>' +
      '<p class="formats">JPEG or PNG, max 10mb</p>';
    remove.innerHTML = '<a href="#" class="remove-image">Remove image</a>';
    remove.classList.add("remove");
    info.appendChild(remove);
    info
      .getElementsByTagName("button")[0]
      .addEventListener("click", function (e) {
        e.preventDefault();
        drop.classList.remove("show-info");
      });
    remove
      .getElementsByClassName("remove-image")[0]
      .addEventListener("click", function (e) {
        e.preventDefault();
        idField.value = null;
        preview.innerHTML = '<div class="image-placeholder"></div>';
        remove.classList.remove("shown");
      });
    drop.appendChild(info);
    drop.classList.add("show-info");

    if (preview.getElementsByTagName("img").length > 0) {
      remove.classList.add("shown");
    }

    drop.ondragover = function (event: DragEvent) {
      event.preventDefault();
      drop.classList.add("show-info");
      drop.classList.add("dragover");
    };

    drop.ondragleave = function () {
      drop.classList.remove("dragover");
    };

    drop.ondragend = function (event: DragEvent) {
      drop.classList.remove("dragover");
      clearItems(event.dataTransfer);
    };

    const handleUpload = (file: File, success?: () => void) => {
      if (validTypes.indexOf(file.type) == -1) {
        alert("Only images in JPEG or PNG formats are supported");
        return;
      }

      if (file.size > maxSize) {
        alert("The file is too big! Maximum is 10mb");
        return;
      }

      preview.innerHTML = "";
      uploadImage(file, function (response: Response) {
        idField.value = `${response.id}`;
        const img = document.createElement("img");
        img.addEventListener("load", function () {
          drop.classList.remove("loading");
        });
        img.src = response.preview_url;
        preview.appendChild(img);
        drop.classList.add("show-info");
        remove.classList.add("shown");
        if (success) {
          success();
        }
      });
    };

    drop.ondrop = function (event: DragEvent) {
      const files = getFiles(event.dataTransfer);
      event.preventDefault();
      drop.classList.remove("dragover");
      drop.classList.add("loading");

      if (files.length > 0) {
        handleUpload(files[0]);
      }
    };

    fileInput.addEventListener("change", (evt: Event) => {
      const target = evt.target as HTMLInputElement;
      const files = target.files;

      if (files[0]) {
        handleUpload(files[0], () => {
          target.value = null;
        });
      }
    });
  });
}
