import React, { useRef } from "react";
import Filter from "./RecentSales/Filter";
import List from "./RecentSales/List";
import Pagination from "./RecentSales/Pagination";
import useSalesStore from "./RecentSales/useSalesStore";

interface Props {
  sales: Sale[];
}

export default function RecentSales(props: Props) {
  const scrollRef = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useSalesStore(props.sales);

  const scrollToTop = () => {
    if (!scrollRef.current || !window) {
      return;
    }

    const elem = scrollRef.current,
      rect = elem.getBoundingClientRect(),
      bodyRect = document.body.getBoundingClientRect(),
      offset = rect.top - bodyRect.top - 40;

    if (window.scrollY > offset) {
      window.scrollTo(0, offset);
    }
  };

  return (
    <div className="recent-sales" ref={scrollRef}>
      <Filter sales={props.sales} state={state} dispatch={dispatch} />
      <List sales={props.sales} state={state} />
      <Pagination
        sales={props.sales}
        state={state}
        dispatch={dispatch}
        scrollToTop={scrollToTop}
      />
    </div>
  );
}
