import React, { useState } from "react";

interface Props {
  sale: Sale;
}

function title(sale: Sale) {
  if (sale.book_url) {
    return <a href={sale.book_url}>{sale.title}</a>;
  } else {
    return sale.title;
  }
}

function attribute(label: string, value: string) {
  if (value) {
    return (
      <div className="attribute">
        <span className="label">{label}:</span>
        {value}
      </div>
    );
  } else {
    return <></>;
  }
}

export default function ListItem(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const { sale } = props;

  const changeExpanded = (value: boolean) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    setExpanded(value);
  };

  return (
    <div className={"list-item" + (expanded ? " expanded" : "")}>
      <div className="cover">
        {sale.image && <img src={sale.image} alt={sale.title} />}
      </div>
      <div className="details">
        <div className="column name">
          <b>{sale.author}</b>
          <br />
          <div className="title">
            {title(sale)}
            <br />(<span lang="nb">{sale.original_title}</span>)
          </div>
        </div>
        <div className="column sold-to">
          {attribute("Sold to language/country", sale.sold_to)}
          {attribute("Sold to publisher", sale.sold_to_publisher)}
          {attribute("Translator", sale.translator)}
        </div>
        <div className="column genre hideable">
          {attribute("Genre", sale.genre)}
        </div>
        <div className="column dates hideable">
          Q{sale.quarter} {sale.year}
          <br />
          {sale.due}
        </div>

        <div className="expanded-toggle">
          {!expanded && (
            <a href="#" onClick={changeExpanded(true)}>
              Show more
            </a>
          )}
          {expanded && (
            <a href="#" onClick={changeExpanded(false)}>
              Show less
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
