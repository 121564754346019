import { useReducer } from "react";

const perPage = 50;

const reducer = (state: SalesState, action: SalesAction): SalesState => {
  switch (action.type) {
    case "changeYear":
      if (action.payload == "") {
        return { ...state, year: action.payload, quarter: "", page: 1 };
      } else {
        return { ...state, year: action.payload, page: 1 };
      }
    case "changeQuarter":
      if (state.year == "") {
        return state;
      } else {
        return { ...state, quarter: action.payload, page: 1 };
      }
    case "changeGenre":
      return { ...state, genre: action.payload, page: 1 };
    case "changeSoldTo":
      return { ...state, soldTo: action.payload, page: 1 };
    case "changePage":
      return { ...state, page: action.payload };
    default:
      throw new Error();
  }
};

export function filter(state: Partial<SalesState>, allSales: Sale[]) {
  let sales = allSales;
  if (state.year) {
    sales = sales.filter((s) => s.year == state.year);
  }
  if (state.quarter) {
    sales = sales.filter((s) => s.quarter == state.quarter);
  }
  if (state.genre) {
    sales = sales.filter((s) => s.genre == state.genre);
  }
  if (state.soldTo) {
    sales = sales.filter((s) => s.sold_to == state.soldTo);
  }
  return sales;
}

export function pageCount(state: SalesState, sales: Sale[]) {
  return Math.ceil(filter(state, sales).length / perPage) || 1;
}

export function paginate(state: SalesState, sales: Sale[]) {
  return filter(state, sales).slice(
    (state.page - 1) * perPage,
    state.page * perPage
  );
}

function lastQuarter(sales: Sale[], year: string) {
  return filter({ year: year }, sales)
    .map((s) => s.quarter)
    .sort()
    .reverse()[0];
}

export default function useSalesStore(
  sales: Sale[]
): [SalesState, React.Dispatch<SalesAction>] {
  const years = [...new Set(sales.map((s) => s.year))].sort().reverse();

  return useReducer(reducer, {
    options: {
      year: years,
      genre: [...new Set(sales.map((s) => s.genre))].sort(),
      soldTo: [...new Set(sales.map((s) => s.sold_to))].sort()
    },
    year: years[0],
    quarter: lastQuarter(sales, years[0]),
    genre: "",
    soldTo: "",
    page: 1
  });
}
