import React from "react";

export type DropdownOption = [string, string];

interface Props {
  disabled?: boolean;
  label: string;
  name: string;
  onChange: (value: string) => void;
  options: DropdownOption[];
  value: string;
}

export default function Dropdown(props: Props) {
  const { options } = props;

  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    evt.preventDefault();
    props.onChange(evt.target.value);
  };

  return (
    <div className={"select-wrapper " + props.name}>
      <select
        name={props.name}
        value={props.value}
        onChange={handleChange}
        disabled={props.disabled}
        aria-controls="recent-sales-list"
        aria-label={props.label}>
        {options.map(([label, value]: [string, string]) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
